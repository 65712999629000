<template>
  <div>
    欢迎。。。
  </div>
</template>

<script>
export default {
  name: 'LiMeng',
  data() {
    return {
      description: '专属介绍页面'

    }
  }
}
</script>
<style lang="less" scoped></style>